import * as React from "react";
import { AnimatePresence, motion } from "framer-motion";

const IframeModal = ({ isToggled, toggleModal, bg, children }) => {
  return (
  	<AnimatePresence>
  		
  		{isToggled && (document.body.style.overflow="hidden")}
  		
  		{isToggled && (
	  		<motion.div
  				initial={{ opacity: 0 }}
  				animate={{ opacity: 1 }}
  				exit={{ opacity: 0 }}
  				className="insetModalOverlay"
  				style={{ backgroundColor: bg}}
  				onClick={() => toggleModal()}
  			>
  				
	  			<motion.div
	  				initial={{ opacity: 0, rotateX: 40 }}
	  				animate={{ opacity: 1, rotateX: 0, transition: {duration: .5} }}
	  				exit={{ opacity: 0, rotateX: -40, transition: {duration: .5} }}
	  				className="iframeModal"
	  			>
	  				{children}
	  			</motion.div>
	  			
	  		</motion.div>
  		)}
  	</AnimatePresence>
  );
}

export default IframeModal;