import React, { Component } from 'react';
import { motion } from "framer-motion";

import { NavLink, useLocation, withRouter } from "react-router-dom";
import { slide as Menu } from 'react-burger-menu'
import discord from "../assets/discord-brands.svg"; 


class Sidebar extends React.Component {
  showSettings (event) {
    event.preventDefault();
  }

  render () {
    return (
	    <>
			<Menu right isOpen={ false }>
					<a id="maplink" href="/#/map">MAP</a>
				<a id="watch" href="/#/watch">WATCH</a>
				<a id="play" href="https://yawningportal.dnd.wizards.com/" target="_blank">PLAY</a>
				<a id="revelsend" href="/#/revelsend">REALITY RP</a>
				<a id="marketplace" href="/#/marketplace">MARKETPLACE</a>
				<a id="schedule" href="/#/schedule">SCHEDULE</a>
				<a href="https://discord.com/channels/720382370141700156/720386742632579134" target="_blank"><img src={discord} />CHAT</a>
			</Menu>
		</>
    );
  }
}

export default Sidebar;