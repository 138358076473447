import React, { Component } from 'react';
import { motion } from "framer-motion";
import YouTube from 'react-youtube-embed'
import axios from 'axios';

import Layout from "../Layout"


class Watch extends Component {

	constructor(props) {
		super(props);
		
		this.state = {
            videoid: ""
		};
	}
	
	fetchJSON() {
		this.setState({...this.state, videoid: 'egS_67zD0T8' });
	}

	componentDidMount() {
        this.fetchJSON();	
	}
	
	render() {
		if(!this.state.videoid) {
			return false;
		}

		return(
		  	<>
			<Layout>
			  	<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
				>
					<div class="content">
						<h1 className="label">
							<span/>
								<span>Watch</span>
							<span/>
						</h1>
						
						<YouTube id={this.state.videoid}/>
		
					</div>
									
				</motion.div>		
			</Layout>
		  </>
		)
	}
}


export default Watch;