import React from "react"
import { motion } from "framer-motion";

export const Footer = () => (
	<>
	
    <footer>
    	©1993-2020 Wizards of the Coast LLC, a subsidiary of Hasbro, Inc. <span>All Rights Reserved.</span>
    </footer>
    
    <div id="pagebg"></div>
    </>
)