import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

import { AnimatePresence } from "framer-motion";
import { HashRouter as Router, Switch, Route, useParams } from "react-router-dom";

import { Index } from "./pages/Index";
import Watch from "./pages/Watch";
import { Play } from "./pages/Play";
import { Marketplace } from "./pages/Marketplace";
import { Schedule } from "./pages/Schedule";
import { Chat } from "./pages/Chat";

import axios from 'axios';

import RevelsEnd from "./pages/RevelsEnd";
import MapComponent from "./components/MapComponent";
import MapDetail from "./pages/MapDetail";
import Layout from "./Layout"
import './maps.css';

class App extends React.Component {
	
	constructor(props) {
        super(props);
        this.state = {
            isFetchingMap: false,
            isFetchingRevelsend: false,
            map: {},
            revelsend: {}
        };
    }
	
	componentDidMount(){
		
	}
	
	render() {
		return (
		  <>
		  <Router>
		    <Route
		      render={({ location }) => (
		          <Switch location={location} key={location.pathname}>
		            <Route exact path="/" component={Index} />
		            <Route exact path="/map" render={ () => <Layout><MapComponent /></Layout> } />
		            <Route exact path="/watch" component={Watch} />
		            <Route exact path="/play" component={Play} />
		            <Route exact path="/revelsend" render={() => <RevelsEnd json={this.state.revelsend} />} />
		            <Route exact path="/marketplace" component={Marketplace} />
		            <Route exact path="/schedule" component={Schedule} />
		            <Route exact path="/chat" component={Chat} />
		            <Route exact path="/map/:detail" component={MapDetail} />
		          </Switch>
		      )}
		    />
		  </Router>
		  </>
		)

		const rootElement = document.getElementById("root");
		ReactDOM.render(<App />, rootElement);
	}
}

export default App;