import React, { useState, useEffect } from "react";
import PropTypes from "prop-types"

import { motion, AnimatePresence } from "framer-motion";
 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Header } from "./components/Header";
import { Footer } from "./components/Footer";

import './layout.css';

const Layout = ({ children }) => {

	return (
		<>
		  <ToastContainer 
			position="bottom-right"
			autoClose={4000}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss={false}
			draggable={false}
			limit={6} />
			
		<AnimatePresence>
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				transition={{ duration: .3 }}
				exit={{ opacity: 0 }}
			>
			
				<Header />
					<main>{children}</main>
				<Footer />
		  
			</motion.div>
			</AnimatePresence>	
		</>
	)
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
