import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";

import Layout from "../Layout"
import MapComponent from "../components/MapComponent";
import Modal from "../components/Modal";

import '../maps.css';
import example from "../assets/example-content.jpg";
import hotspot from "../assets/hot-spot-test.svg";


function MapDetail(){
	const [isToggled, setToggle] = useState(false);
	const { detail } = useParams();
	
	if (isToggled) {
		document.body.style.overflow = "hidden";
	} else {
		document.body.style.overflow = "auto";
	}
	
 	return (
		<Layout>
			<div class="content">
				<h1>{detail}</h1>
				<div class="art">
					
					<motion.img 
						src={hotspot}
						className="hotspot"
						initial={{ opacity: 1 }}
						animate={{ opacity: 0 }}
						transition={{ duration: 1 }}
						whileHover={{ opacity: [0, .8], transition: { duration: .3, yoyo: Infinity } }}
						exit={{ opacity: 0 }}
						onClick={() => setToggle(true)}
					/>
						
					<img src={"./assets/" + detail +".jpg"} width="100%" />
				</div>
				
				<Modal isToggled={isToggled} setToggle={setToggle}>
					<div style={{ position: "relative", width: "100%", padding: "1em" }}>
						<img src={example} width="100%"/>
						<img src={example} width="100%"/>
						<img src={example} width="100%"/>
						<img src={example} width="100%"/>
						<img src={example} width="100%"/>
					</div>
				</Modal>
			</div>
		</Layout>
	)
};
  
export default MapDetail;