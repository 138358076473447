import React, { useState } from "react";
import ReactDOM from "react-dom";
import { motion, AnimatePresence } from "framer-motion";

import HeaderLinks from "../components/HeaderLinks";
import { FooterIntro } from "../components/FooterIntro";

import logo from "../assets/dnd-event-logo-white.svg";
import streak from "../assets/streak.svg";

import discord from "../assets/discord-white.svg"; 

function launch(href, isOpen, setIsOpen) {
	setIsOpen(!isOpen)
	setTimeout(function() {
		this.document.location.href = href;
	}, 500)
}

export const Index = () => {
	const [isOpen, setIsOpen] = useState(true);

	return (
			
		<AnimatePresence>
		
		{isOpen && (
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0, scale: 1.5 }}
				transition={{ duration: .5 }}
			>
	
				<header class="nobg">
						<HeaderLinks discord={discord} />
				</header>
				<main>
					<div class="content intro">
						<img src={logo} class="logo" />
						
						<p>
							Visit the interactive map of Icewind Dale to uncover setting information, clues for Reality RP, secrets, and more! While there, you’ll find event features are located at the top right of the map.
						</p>
						
						<img src={streak} class="streak" />
						
						<motion.a
						  whileHover={{
						    scale: 1.08,
						    transition: { duration: .5 },
						  }}
						  whileTap={{ scale: 1.12 }}
						  className="button"
						  onClick={() => launch("/#/map", isOpen, setIsOpen)}
						>
								BEGIN QUEST
						</motion.a>
					</div>
				</main>
				
				<FooterIntro />
				
			</motion.div>
		)}
		
		</AnimatePresence>	
	)
};