import React from "react";
import { AnimatePresence, motion } from "framer-motion";


const Targetmajor = () => (
	
	<motion.svg 
		animate={{ transition: { duration: .3, yoyo: Infinity } }}
	    width="70%"
	    viewBox="0 0 30 30"
	    preserveAspectRatio="xMidYMid meet"
	    class="active"
    >
		<circle cx="15" cy="15" r="8" fill="red "style={{filter: "url(#shadow)"}} />
		<circle cx="15" cy="15" r="8" stroke="white" stroke-width="1" fill="red" />
		<circle cx="15" cy="15" r="3" fill="white" />
	</motion.svg>
)


function MapNodes(props) {
	
    const RenderedNodes = props.json.nodes.filter((node) => {
		if(node.parent == props.id){
			return true
        } else {
			return false
		}
    }).map((node, i) => {
	    if (node.type == "major") {
			return (
				<>
			    	<button style={{top: node.top, left: node.left}} className={node.labelposition} onClick={() => props.toggleSubnodeModal(node.id, node.iframe)}><Targetmajor /><div className="label"><img src={`./assets/mapnames/${node.id}-hover.svg`} className="labelHover"/><img src={`./assets/mapnames/${node.id}.svg`} className="label"/></div></button>
				</>
			)
	    } else if (node.type == "minor") {
			return (
				<>
			    	<button style={{top: node.top, left: node.left}} className={node.labelposition} onClick={() => props.toggleSubnodeModal(node.id, node.iframe)}><Targetmajor /><div className="label"><img src={`./assets/mapnames/${node.id}-hover.svg`} className="labelHover"/><img src={`./assets/mapnames/${node.id}.svg`} className="label"/></div></button>
				</>
			)
	    }
	})
		
    return (
	    <>
		    <div class="subNodeMap">
	        	{RenderedNodes}
	        </div>
        </>
    );
};

export default MapNodes;