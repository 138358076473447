import * as React from "react";
import { motion } from "framer-motion";

import Layout from "../Layout"
import mapbg from "../assets/map-background.jpg";
import dice from "../assets/loading-dice.svg";

export const Schedule = () => (
  <>
	<Layout>
	  	<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
		>
		
			<div class="content" style={{marginLeft: "2rem", marginRight: "2rem"}}>
			
				<div className="schedule" style={{backgroundImage: `url(${mapbg})` }}>
					<h1 className="label">
						<span/>
							<span>Schedule</span>
						<span/>
					</h1>
					
					<h3 style={{textAlign: "center"}}><img src={dice} style={{display: "inline-block", width: 15, transform: "translateY(3px)", marginRight: 5}} /> Live Game</h3>
					
					<div className="flex3">
						<div className="whiteBlock">
							<h2>June 18, 2020</h2>
							
							<span>
							10:00 AM
							<h3>Baldur’s Gate 3 Gameplay</h3>	
							</span>
							<span>
							11:30 AM
							<h3>IGN Aftershow</h3>
							<i>Host: IGN<br/>
							Sven Vincke, Greg Tito</i>
							</span>
							<span>
							12:00 PM
							<h3>Avalanche Reveal</h3>
							<i>Hosts: Anna Prosser, Mica Burton<br/>
							Chris Perkins<br/>
							Jeremy Crawford</i>
							</span>
							<span>
							12:30 PM
							<h3>IDW Comics Reveal</h3>
							<i>Hosts: Anna Prosser, Mica Burton<br/>
							Aimee Garcia, AJ Mendez, Jim Zub, Jody Houser</i>
							</span>
							<span>
							1:00 PM
							<h3>Ten Speed Press Reveal</h3>
							<i>Hosts: Anna Prosser, Mica Burton<br/>
							Jon Peterson, Kyle Newman, Michael Witwer</i>
							</span>
							<span>
							1:30 PM
							<h3>WizKids Reveal</h3>
							<i>Hosts: Anna Prosser, Mica Burton<br/>
							Vee Crafting Muse, Patrick O’Hagan</i>
							</span>
							<span>
							2:00 PM
							<h3>
								<img src={dice} style={{display: "inline-block", width: 15, transform: "translateY(3px)", marginRight: 5}} />
								Deborah’s Game</h3>
							<i>DM: Deborah Ann Woll<br/>
							Sam Richardson, Janina Gavankar, Amy Acker, Matthew Lillard, Jay Ellis</i>
							</span>
							<span>
							4:00 PM
							<h3>Aftershow</h3>
							<i>Anna Prosser, Mica Burton, Mark Hulmes</i>
							</span>
							<span>
							4:15 PM 
							<h3>Reality RP Show</h3>
							<i>Mark Meer, Contestants</i>
							</span>
							<span>
							4:30 PM
							<h3>Aftershow</h3>
							<i>Anna Prosser, Mica Burton, Mark Hulmes</i>
							</span>
						</div>
						<div className="whiteBlock">
							<h2>June 19, 2020</h2>
							
							<span>
							9:30 AM
							<h3>Preshow</h3>
							<i>Anna Prosser, Mica Burton</i>
							</span>
							<span>
							10:00 AM
							<h3>
								<img src={dice} style={{display: "inline-block", width: 15, transform: "translateY(3px)", marginRight: 5}} />
								Red Nose Day Fundraiser Game</h3>
							<i>DM: Chris Perkins<br/>
							Martin Starr, Brett Gelmen, Maitreyi Ramakrishnan, Felicia Day</i>
							</span>
							<span>
							12:00 PM
							<h3>Red Nose Day Spotlight</h3>
							</span>
							<span>
							12:30 PM
							<h3>Black AF Sidetable</h3>
							<i>Omega Jones, Tanya DePass, B Dave Walters, TK Johnson, Aabria Iyengar</i>
							</span>
							<span>
							2:00 PM
							<h3>
								<img src={dice} style={{display: "inline-block", width: 15, transform: "translateY(3px)", marginRight: 5}} />
								Comedians Play D&D</h3>
							<i>DM: Kate Welch<br/>
							Sue Perkins, Melissa Villasenor, Sue Perkins, Reggie Watts, Kevin Sussman, Brian Posehn, Thomas Middleditch</i>
							</span>
							<span>
							4:00 PM
							<h3>Aftershow</h3>
							<i>Anna Prosser, Mica Burton, Mark Hulmes</i>
							</span>
							<span>
							4:15 PM
							<h3>Reality RP Show</h3>
							<i>Mark Meer, Contestants</i>
							</span>
							<span>
							4:30 PM
							<h3>Aftershow</h3>
							<i>Anna Prosser, Mica Burton, Mark Hulmes</i>
							</span>
						</div>
						<div className="whiteBlock">
							<h2>June 20, 2020</h2>
							
							<span>
							9:30 AM
							<h3>Preshow</h3>
							<i>Anna Prosser, Mica Burton</i>
							</span>
							<span>
							10:00 AM
							<h3>
								<img src={dice} style={{display: "inline-block", width: 15, transform: "translateY(3px)", marginRight: 5}} />
								D&D’s Lost Odyssey Heroes</h3>
							<i>DM: Chris Perkins<br/>
							David Harbour, Brandon Routh, Karen Gillan, Pom Klementieff</i>
							</span>
							<span>
							12:00 PM
							<h3>
								<img src={dice} style={{display: "inline-block", width: 15, transform: "translateY(3px)", marginRight: 5}} />
								D&D’s Cast of Thrones</h3>
							<i>DM: Kate Welch<br/>
							Iwan Rheon, Natalia Tena, Daniel Portman, Kristian Nairn, Gemma Whelan</i>
							</span>
							<span>
							2:00 PM
							<h3>
								<img src={dice} style={{display: "inline-block", width: 15, transform: "translateY(3px)", marginRight: 5}} />
								WWE Plays D&D</h3>
							<i>DM: Jeremy Crawford<br/>
							Ember Moon, Xavier Woods, Alexa Bliss, Dio Maddin, Tyler Breeze</i>
							</span>
							<span>
							4:00 PM
							<h3>Aftershow</h3>
							<i>Anna Prosser, Mica Burton, Mark Hulmes</i>
							</span>
							<span>
							4:15 PM
							<h3>Reality RP Show</h3>
							<i>Mark Meer, Contestants</i>
							</span>
							<span>
							4:30 PM
							<h3>Aftershow</h3>
							<i>Anna Prosser, Mica Burton, Mark Hulmes</i>
							</span>
						</div>
					</div>
				</div>
			</div>
							
		</motion.div>		
	</Layout>
  </>
);