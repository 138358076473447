import React, { Component } from 'react';
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import jsondata from "../nodes.json";

import Layout from "../Layout"
import VideoBlocks from "../components/VideoBlocks"

import discord from "../assets/discord-white.svg";
import realityrp from "../assets/reality-rp-logo.svg";

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
	    delay: 1.8,
	    duration: 2,
	    delayChildren: 2.5,
		staggerChildren: 0.1
    }
  }
}

const item = {
  hidden: { y: 50, opacity: 0 },
  show: { y: 0, opacity: 1 }
}


class RevelsEnd extends Component {

	constructor(props) {
		super(props);
		
		this.state = {
            json: null
		};
	}
	
	fetchJSON() {
		var jsonArray = jsondata.revelsend
		var jsonProcessed = [];
		
		jsonArray.forEach(function(row){
			jsonProcessed.push({"data": row, "status": 1})
		})
		
		this.setState({...this.state, json: {"revelsend": jsonProcessed} });
	}

	componentDidMount() {
        this.fetchJSON();	
	}
	
	render() {
		if(!this.state.json) {
			return false;
		}
		
		var json = this.state.json;
		
		return (
		<Layout>
		  	<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
			>
				<div class="content">
					
					<div style={{ position: "relative", width: "100%", padding: "0", boxSizing: "border-box" }}>
						<div className="modalHeader">
							
							<h1 className="label rp">
								<span/>
									<span>Revel's End</span>
								<span/>
							</h1>
							
							
							<Link to="/map" className="backLink rp">
								<motion.button className="back"
					  				whileHover={{ backgroundColor: "#ff0000", transition: {delay: 0, duration: .3} }}
								>
									&lt; BACK TO MAP
								</motion.button>
							</Link> 
							
							
							<a href="https://discord.com/channels/720382370141700156/720386790858686555" target="_blank" className="discord">
								<motion.button className="back"
					  				whileHover={{ backgroundColor: "#ff0000", transition: {delay: 0, duration: .3} }}
								>
									<img src={discord} width="20"/> Reality Game Discord
								</motion.button>
							</a> 
						</div>
						<motion.div
							className="revelsendContainer"
							initial={{ opacity: 0, scale: 1.3 }}
			  				animate={{ opacity: 1, scale: 1, transition: {delay: 0, duration: 2.5} }}
						>
							<motion.div
								className="revelsendHeader"
								initial={{ opacity: 0 }}
				  				animate={{ opacity: 1, transition: {delay: 1.5, duration: 2} }}
				  			>
				  				<h2>WELCOME TO</h2>
								<img src={realityrp} width="50%" />
							</motion.div>
							
							<motion.div className="revelsendGrid"
							    variants={container}
							    initial="hidden"
							    animate="show"
							  >
							
								<motion.div className="logoCenter"
									initial={{ opacity: 0 }}
					  				animate={{ opacity: 1, transition: {delay: 2, duration: 2} }}
					  			>
									<img src={`./assets/crests/revelsend-icon.svg`} />
								</motion.div>
								
								<VideoBlocks json={this.state.json} />

							</motion.div>
							
							
							<motion.div className="illustration">
								Illustration by Titus Lunter
							</motion.div>
							
							
							<motion.div
								initial={{ opacity: 0 }}
				  				animate={{ opacity: 1, transition: {delay: 2.5, duration: 2} }}
				  				className="revelsEndOverlay"
							/>
						</motion.div>
					</div>
				
				</div>
								
			</motion.div>		
		</Layout>
		)
		
		return (
			<></>
		)
	}
				
}

export default RevelsEnd;