import * as React from "react";
import { AnimatePresence, motion } from "framer-motion";

const SubnodeModal = ({ isToggled, toggleModal, bg, children }) => {
  return (
  	<AnimatePresence>
  		
  		{isToggled && (document.body.style.overflow="hidden")}
  		{!isToggled && (document.body.style.overflow="auto")}
  		
  		{isToggled && (
	  		<motion.div
  				initial={{ opacity: 0 }}
  				animate={{ opacity: 1 }}
  				exit={{ opacity: 0 }}
  				className="modalOverlay"
  				onClick={() => toggleModal()}
  			>
  				
	  			<motion.div
	  				initial={{ y: 50 }}
	  				animate={{ y: 0 }}
	  				exit={{ y: 30 }}
	  				className="modal"
	  				onClick={(e) => e.stopPropagation()}
	  			>
	  				{children}
	  			</motion.div>
	  			
	  		</motion.div>
  		)}
  	</AnimatePresence>
  );
}

export default SubnodeModal;