import React from "react"
import { motion } from "framer-motion";

export const FooterIntro = () => (
	<>
	
	<div id="social">
		<a href="https://www.instagram.com/dndwizards/">
			<motion.i
			  whileHover={{
			    scale: 1.3,
			    transition: { duration: .5 },
			  }}
			  whileTap={{ scale: 1.4 }}
			  className="fab fa-instagram fa-2x">
			</motion.i>
		 </a>
		 
		<a href="https://www.youtube.com/user/DNDWizards">
			<motion.i
			  whileHover={{
			    scale: 1.3,
			    transition: { duration: .5 },
			  }}
			  whileTap={{ scale: 1.4 }}
			  className="fab fa-youtube fa-2x">
			</motion.i>
		 </a>
			 
		<a href="https://twitter.com/Wizards_DnD">
			<motion.i
			  whileHover={{
			    scale: 1.3,
			    transition: { duration: .5 },
			  }}
			  whileTap={{ scale: 1.4 }}
			  className="fab fa-twitter fa-2x">
			</motion.i>
		</a>
	</div>
	
    <footer class="intro">
    	©1993-2020 Wizards of the Coast LLC, a subsidiary of Hasbro, Inc. <span>All Rights Reserved.</span>
    </footer>
    
    <div id="pagebg-blue"></div>
    </>
)