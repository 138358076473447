import React, { Component } from 'react';
import { AnimatePresence, motion } from "framer-motion";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import EmbedModal from "../components/EmbedModal";
import close from '../assets/close.svg';

const item = {
  hidden: { y: 50, opacity: 0 },
  show: { y: 0, opacity: 1 }
}


class VideoBlocks extends Component {

	constructor(props) {
		super(props);
		
		this.state = {
			modal: null,
			modalContents: null,
			modalOpen: false
		};
	}
	
	toggleModal = (handle, contents) => {
		this.setState({modalOpen: !this.state.modalOpen})
		this.setState({modal: handle, modalContents: contents})
	}
		
	
	render () {			
		const modal = this.props.json.revelsend.filter((row)=> {
			if (row.data.handle == this.state.modal) {
				return row
			} else {
				return false
			}
		}).map((row, i) => {
			return row.data.embed
		})
		
		return (
			<>
			{this.props.json.revelsend.map((row, i) => {
				return (
					<motion.div variants={item}
							className={`gridBox status${row.status}`}
						>
						
						{row.status == 0 &&
							<div className="eliminated">
								<img src={`./assets/eliminated.svg`} width="100%" />
							</div>
						}
						
						<div className="video">
							<img src={`./assets/realityrp/${row.data.handle}-vid.png`} onClick={()=> this.toggleModal(row.data.handle, row.data.embed)}/>
						</div>
						
						<div className="videomobile">
							<a href={row.data.link}><img src={`./assets/realityrp/${row.data.handle}-vid.png`}/></a>
						</div>
						
						<div className="info">
							<div className="logo">
								<a href={`https://www.instagram.com/${row.data.handle}/`}><img src={`./assets/realityrp/${row.data.handle}.jpg`} /></a>
							</div>
							
							<div className="description">
								<h3>{row.data.title}</h3>
								
								<a href={`https://www.instagram.com/${row.data.handle}/`} className="igLink">
									<motion.i
									  whileHover={{
									    scale: 1.1,
									    transition: { duration: .5 },
									  }}
									  whileTap={{ scale: 1.2 }}
									  className="fab fa-instagram">
									</motion.i>
									@{row.data.handle}
								 </a>
							 </div>
						</div>
					</motion.div>
				)
			})}
			
			
			<EmbedModal isToggled={this.state.modalOpen} toggleModal={this.toggleModal.bind(this)} bg="rgba(0,0,0,.7)">
			
				<div style={{ position: "relative", padding: "1em", boxSizing: "border-box", height: "100%", width: 600, margin: "0 auto" }}>
		  			<motion.button
		  				initial={{ x: -20, opacity: 0 }}
		  				animate={{ x: 0, opacity: 1, transition: {delay: .3, duration: .5} }}
		  				exit={{ x: -20, opacity: 0, transition: {duration: .5} }}
		  				className="closeButton"
		  				style={{top: "1.7em"}}
		  				whileHover={{ backgroundColor: "#ff0000", transition: {delay: 0, duration: .3} }}
		  				onClick={() => this.toggleModal()}
		  			>
						<img src={close} width="15"/>
		  			</motion.button>
	  			
			        <iframe srcdoc={modal} style={{width: 520, height: "100%", border: "none"}}></iframe>
		        </div>
		        
			</EmbedModal>
			</>
		)
	}
}


export default VideoBlocks;