import React, { Component } from 'react';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { AnimatePresence, motion } from "framer-motion";


import Iframe from '../components/Iframe.js';
import { Times } from "react-lodash"
import Img from 'react-image';

import { NavLink, useLocation, useParams, withRouter } from "react-router-dom";
import Snowfall from 'react-snowfall'

import Modal from "../components/Modal";
import InsetModal from "../components/InsetModal";
import IframeModal from "../components/IframeModal";
import SubnodeModal from "../components/SubnodeModal";

import MapNodes from "../components/MapNodes";

import map from '../assets/maps/map.jpg';
import plus from '../assets/search-plus-regular.svg';
import minus from '../assets/search-minus-regular.svg';
import expand from '../assets/expand-regular.svg';
import snowflake from '../assets/snowflake.svg';
import close from '../assets/close.svg';
import back from '../assets/left-chevron.svg';

import hotspottest from "../assets/hotspot-test.svg";

import jsondata from "../nodes.json";

const Targetmajor = () => (
	
	<motion.svg 
		animate={{ transition: { duration: .3, yoyo: Infinity } }}
	    width="100%"
	    viewBox="0 0 30 30"
	    preserveAspectRatio="xMidYMid meet"
	    class="active"
    >
		<circle cx="15" cy="15" r="8" fill="red "style={{filter: "url(#shadow)"}} />
		<circle cx="15" cy="15" r="8" stroke="white" stroke-width="1" fill="red" />
		<circle cx="15" cy="15" r="3" fill="white" />
	</motion.svg>
)

class MapComponent extends Component {

	constructor(props) {
		super(props);
		
		var maxZoom;
		var minZoom;
		var winWidth = window.innerWidth;
		var winHeight = window.innerHeight;
		var defaultX;
		var defaultY;
		var initZoom;
		var zoomFullHeight = winHeight / ((winWidth / 3000) * 2145);
		var desktopZoom = zoomFullHeight;
		zoomFullHeight < 1 ? desktopZoom = 1 : desktopZoom = desktopZoom;
		
		if (winWidth < 800) {
			minZoom = zoomFullHeight;
			maxZoom = 6;
			initZoom = zoomFullHeight;
			defaultX = 1;
			defaultY = 1;
		} else if (winHeight < 2145) {
			minZoom = 1;
			maxZoom = 2.5;
			initZoom = desktopZoom;
			defaultX = 1;
			defaultY = 1;
		}
		
		this.state = {
		    winWidth: window.innerWidth,
		    winHeight: window.innerHeight,
			minZoom: minZoom,
			maxZoom: maxZoom,
			defaultX: defaultX,
			defaultY: defaultY,
			initZoom: initZoom,
			modal: null,
			modalOpen: false,
			insetModal: null,
			insetModalOpen: false,
			subnodeModal: null,
			subnodeModalOpen: false,
			iframeModal: null,
			iframeModalOpen: false,
            iframeHref: null,
            iframe: null,
            json: null
		};
	}
    
    fetchJSON() {
		this.setState({...this.state, json: {"nodes": jsondata.nodes} });
	}

	componentDidMount() {
        this.fetchJSON();
		
		setTimeout(function() {
			var zoomInit = document.getElementById("zoomInit");
			zoomInit.click()
		}, 600)
	}
	
	toggleModal = (param, path) => {
		this.setState({modalOpen: !this.state.modalOpen})
		this.setState({modal: param, iframe: "./assets/twine/" + path})
	}
	
	toggleInsetModal = (id, iframe) => {
		this.setState({insetModalOpen: !this.state.insetModalOpen})
		this.setState({insetModal: id, iframe: "./assets/twine/" + iframe})
	}
	
	toggleSubnodeModal = (param, path) => {
		this.setState({subnodeModalOpen: !this.state.subnodeModalOpen})
		this.setState({subnodeModal: param})
	}
	
	toggleIframeModal = (type, href) => {
		href = "./assets/twine/" + href;
		this.setState({iframeModalOpen: !this.state.iframeModalOpen})
		this.setState({iframeModal: type, iframeHref: href})
	}

	idMatches = (element, search) => {
		return element == this.state.search;
	}
	
	render () {
		if(!this.state.json) {
			return false;
		}
		
		var json = this.state.json;
		
		const modal = json.nodes.filter((node)=> {
			if (node.id == this.state.modal) {
				return node
			} else {
				return false
			}
		}).map((node, i) => {
				
			return (
				<>
				{node.id != "revelsend" && node.modal == "full" && 
					<div style={{ position: "relative", width: "100%", padding: "1em", boxSizing: "border-box" }}>
						<div className="modalHeader">
						
							
							<h2>{node.title}</h2>
							<motion.button className="back"
								initial={{ opacity: 0 }}
								animate={{ opacity: 1, transition: {duration: 1, delay: .5} }}
				  				whileHover={{ backgroundColor: "#ff0000", transition: {delay: 0, duration: .3} }}
								onClick={ () => this.toggleModal() }
							>
								<img src={back} height="12" style={{transform: "translateY(2px)", marginRight: 2}}/> BACK TO MAP
							</motion.button>
						</div>
						{node.id == "reghednomads" &&
							<br/>
						}
						<div className="modalBody">
							{node.subNodes && 
								<>
								<MapNodes toggleSubnodeModal={(id, iframe) => this.toggleSubnodeModal(id, iframe)} id={node.id} json={this.state.json} />
								<img src={`./assets/maps/map-${node.id}.jpg`} width="100%" />
								</>
							}
							{!node.subNodes && node.image &&
								<div class="art">
									
									{node.hotspots &&
										<AnimatePresence>
										{node.hotspots.map((hotspot, key) =>
											<>
												{hotspot.type == "subnode" &&
													<motion.img 
														src={hotspot.hover}
														className="hotspot"
														style={{left: hotspot.left, top: hotspot.top, width: hotspot.width}}
														initial={{ opacity: 1 }}
														animate={{ opacity: 0, transition: {duration: 1} }}
														whileHover={{ opacity: [0, .8], transition: { duration: .3, yoyo: Infinity } }}
														exit={{ opacity: 0 }}
														onClick={(iframe) => this.toggleSubnodeModal(hotspot.nodetitle)}
													/>
	
												}
												
												{hotspot.type != "subnode" &&
													<motion.img 
														src={hotspot.hover}
														className="hotspot"
														style={{left: hotspot.left, top: hotspot.top, width: hotspot.width}}
														initial={{ opacity: 1 }}
														animate={{ opacity: 0, transition: {duration: 1} }}
														whileHover={{ opacity: [0, .8], transition: { duration: .3, yoyo: Infinity } }}
														exit={{ opacity: 0 }}
														onClick={(iframe) => this.toggleIframeModal(hotspot.type, hotspot.href)}
													/>
												}
											</>
										)}
										</AnimatePresence>
									}
									<img src={`./assets/${node.id}.jpg`} width="100%" />
								</div>
							}
							
							{!node.subNodes && node.iframe &&
								<Iframe source={this.state.iframe} />
							}
							 
						</div>
						{node.id != "banditshideout" &&
							<div className="modalSidebar">
								<div className="header" style={{background: "linear-gradient(180deg, " + node.gradTop + " 0%, " + node.gradBottom + " 100%)" }}>
								{node.crest &&
									<img src={`./assets/crests/${node.id}-icon.svg`} className="crest" />
								}
								
								{!node.crest &&
									<img src={`./assets/crests/generic-icon.svg`} className="crest" />
								}
									
								{node.id != "reghednomads" &&
									<h2>{node.title}</h2>
								}
									<p>
										{node.description}
									</p>
								
									{node.services &&
									<span>
										<div>
											<h5>SERVICES</h5>
											<Times n={node.services} iteratee={i => <img src={snowflake} />} />
										</div>
										<div>
											<h5>FRIENDLINESS</h5>
											<Times n={node.friendliness} iteratee={i => <img src={snowflake} />} />
										</div>
										<div>
											<h5>COMFORT</h5>
											<Times n={node.comfort} iteratee={i => <img src={snowflake} />} />
										</div>
									</span>
									}
		
									<div className="bg">
									{node.crest &&
										<img src={`./assets/crests/${node.id}-icon.svg`} />
									}
									</div>
								
								</div>
								
								{node.id != "reghedglacier" &&
								<div className="description">
									
									<div className="col3">
										{node.population &&
										<p>
											<b>Population</b><br/>
											{node.population}
										</p>
										}
										
										{node.leader &&
										<p>
											<b>Leader</b><br/>
											{node.leader.split('\n').map((item, key) => {
											  return <span key={key}>{item}<br/></span>
											})}
										</p>
										}
										
										{node.tribe1 &&
										<p>
											<img src={`./assets/elk-tribe-icon.svg`} style={{width: "100%"}} />
											<b>The Elk Tribe</b><br/>
											{node.tribe1.split('\n').map((item, key) => {
											  return <span key={key}>{item}<br/></span>
											})}
										</p>
										}
									</div>
									
									<div className="col3">
										{node.heraldry &&
										<p>
											<b>Heraldry</b><br/>
											{node.heraldry.split('\n').map((item, key) => {
											  return <span key={key}>{item}<br/></span>
											})}
										</p>
										}
										
										{node.sacrifice &&
										<p>
											<b>Sacrifice to Auril</b><br/>
											{node.sacrifice}
										</p>
										}
										
										{node.tribe2 &&
										<p>
											<img src={`./assets/tiger-tribe-icon.svg`} style={{width: "100%"}} />
											<b>The Tiger Tribe</b><br/>
											{node.tribe2.split('\n').map((item, key) => {
											  return <span key={key}>{item}<br/></span>
											})}
										</p>
										}
									</div>
									
									<div className="col3">
										{node.travel &&
										<p>
											<b>Overland Travel</b><br/>
											{node.travel.split('\n').map((item, key) => {
											  return <span key={key}>{item}<br/></span>
											})}
										</p>
										}
										
										{node.tribe3 &&
										<p>
											<motion.img src={`./assets/wolf-tribe-icon.svg`} style={{width: "100%", cursor: "pointer"}} whileHover={{ filter: "drop-shadow(1px 1px 4px #ff0000)", transition: {delay: 0, duration: .3} }} onClick={(iframe) => this.toggleIframeModal("image", "AlukaReghedBook.png")} />
											<b>The Wolf Tribe</b><br/>
											{node.tribe3.split('\n').map((item, key) => {
											  return <span key={key}>{item}<br/></span>
											})}
										</p>
										}
									</div>
									
										
									{node.tribe4 &&
									<div className="col3">
										<p>
											<img src={`./assets/bear-tribe-icon.svg`} style={{width: "100%"}} />
											<b>The Bear Tribe</b><br/>
											{node.tribe4.split('\n').map((item, key) => {
											  return <span key={key}>{item}<br/></span>
											})}
										</p>
									</div>
									}
								</div>
								}
							</div>
						 }
					</div>
				}
				
				{node.id != "revelsend" && node.modal == "description" && 
						<div className="modalCentered">
			  			
							<div className="header" style={{background: "linear-gradient(180deg, " + node.gradTop + " 0%, " + node.gradBottom + " 100%)" }}>
							{node.crest &&
								<img src={`./assets/crests/${node.id}-icon.svg`} className="crest" />
							}
							
							{!node.crest &&
								<img src={`./assets/crests/generic-icon.svg`} className="crest" />
							}
						
					  			<motion.button
					  				initial={{ x: -20, opacity: 0 }}
					  				animate={{ x: 0, opacity: 1, transition: {delay: .3, duration: .5} }}
					  				exit={{ x: -20, opacity: 0, transition: {duration: .5} }}
					  				className="closeButton"
					  				whileHover={{ backgroundColor: "#ff0000", transition: {delay: 0, duration: .3} }}
					  				onClick={() => this.toggleModal()}
					  			>
									<img src={close} width="15"/>
					  			</motion.button>
							
							</div>
							<div className="description">
								<h2>{node.title}</h2>
								{node.description &&
								<p>
									{node.description.split('\n').map((item, key) => {
									  return <span key={key}>{item}<br/></span>
									})}
								</p>
								}
								
								{node.image &&
									<img src={`./assets/${node.id}.jpg`} width="100%" />
								}
								
							</div>
						</div>
				}
				</>
			)
		})
		
		const subnodeModal = json.nodes.filter((node)=> {
			if (node.id == this.state.subnodeModal) {
				return node
			} else {
				return false
			}
		}).map((node, i) => {
				
			return (
				<>
				
				{node.modal == "description" && 
						<div className="modalCentered">
			  			
							<div className="header" style={{background: "linear-gradient(180deg, " + node.gradTop + " 0%, " + node.gradBottom + " 100%)" }}>
							{node.crest &&
								<img src={`./assets/crests/${node.id}-icon.svg`} className="crest" />
							}
							
							{!node.crest &&
								<img src={`./assets/crests/generic-icon.svg`} className="crest" />
							}
						
					  			<motion.button
					  				initial={{ x: -20, opacity: 0 }}
					  				animate={{ x: 0, opacity: 1, transition: {delay: .3, duration: .5} }}
					  				exit={{ x: -20, opacity: 0, transition: {duration: .5} }}
					  				className="closeButton"
					  				whileHover={{ backgroundColor: "#ff0000", transition: {delay: 0, duration: .3} }}
					  				onClick={() => this.toggleSubnodeModal()}
					  			>
									<img src={close} width="15"/>
					  			</motion.button>
							
							</div>
							<div className="description">
								<h2>{node.title}</h2>
								{node.description &&
								<p>
									{node.description.split('\n').map((item, key) => {
									  return <span key={key}>{item}<br/></span>
									})}
								</p>
								}
								
								{node.image &&
									<img src={`./assets/${node.id}.jpg`} width="100%" />
								}
								
							</div>
						</div>
				}
				
				{node.modal == "full" && 
					<div style={{ position: "relative", width: "100%", padding: "1em", boxSizing: "border-box", backgroundColor: "#fff" }}>
						<div className="modalHeader">
						
							
							<h2>{node.title}</h2>
							<motion.button className="back"
								initial={{ opacity: 0 }}
								animate={{ opacity: 1, transition: {duration: 1, delay: .5} }}
				  				whileHover={{ backgroundColor: "#ff0000", transition: {delay: 0, duration: .3} }}
								onClick={ () => this.toggleSubnodeModal() }
							>
								<img src={close} width="12" style={{transform: "translateY(2px)"}}/> CLOSE
							</motion.button>
						</div>
						<div className="modalBody">
							{node.image &&
								<div class="art">
									
									{node.hotspots &&
										<AnimatePresence>
										{node.hotspots.map((hotspot, key) =>
											<>
												<motion.img 
													src={hotspot.hover}
													className="hotspot"
													style={{left: hotspot.left, top: hotspot.top, width: hotspot.width}}
													initial={{ opacity: 1 }}
													animate={{ opacity: 0, transition: {duration: 1} }}
													whileHover={{ opacity: [0, .8], transition: { duration: .3, yoyo: Infinity } }}
													exit={{ opacity: 0 }}
													onClick={(iframe) => this.toggleIframeModal(hotspot.type, hotspot.href)}
												/>
											</>
											
										)}
										</AnimatePresence>
									}
									<img src={`./assets/${node.id}.jpg`} width="100%" />
								</div>
							}
							
							{node.iframe &&
								<Iframe source={this.state.iframe} />
							}
							 
						</div>
						{node.id != "goodmead-fishing" &&
							<div className="modalSidebar">
								<div className="header" style={{background: "linear-gradient(180deg, " + node.gradTop + " 0%, " + node.gradBottom + " 100%)" }}>
									<img src={`./assets/crests/generic-icon.svg`} className="crest" />
									
									<h2>{node.title}</h2>
									
									<p>
										{node.description}
									</p>
								
								</div>
							</div>
						}
					</div>
				}
				</>
			)
		})
		
		const insetModal = json.nodes.filter((node)=> {
			if (node.id == this.state.insetModal) {
				return node
			} else {
				return false
			}
		}).map((node, i) => {
			return (
				<div style={{ position: "relative", padding: "1em", boxSizing: "border-box" }}>
					<div class="insetModalBody">
			  			<motion.button
			  				initial={{ x: -20, opacity: 0 }}
			  				animate={{ x: 0, opacity: 1, transition: {delay: .3, duration: .5} }}
			  				exit={{ x: -20, opacity: 0, transition: {duration: .5} }}
			  				className="closeButton"
			  				whileHover={{ backgroundColor: "#ff0000", transition: {delay: 0, duration: .3} }}
			  				onClick={() => this.toggleInsetModal()}
			  			>
							<img src={close} width="15"/>
			  			</motion.button>

						<div class="image">
						{node.image &&
							<img src={`./assets/${node.id}.jpg`} width="100%" />
						}
						{node.iframe &&
							<Iframe source={this.state.iframe} />
						}
						</div>
						<div class="sidebar">
							<h1>{node.title}</h1>
							{node.description}
						</div>
					</div>
				</div>
			)
		})
	    
	    const nodesRendered = json.nodes.map((node, i) => {
		   if (node.id == "revelsend") {
				return (
						<motion.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1, transition: {duration: 1, delay: .5} }}
						>
				    	<NavLink to="/revelsend"><button style={{top: node.top, left: node.left}} className={node.labelposition} hidden={node.hidden}>
					    		<img src={`./assets/crests/${node.id}-icon.svg`} class="crest"/>
					    		<div className="label"><img src={`./assets/mapnames/${node.id}-hover.svg`} className="labelHover"/>
					    		<img src={`./assets/mapnames/${node.id}.svg`} className="label"/></div></button>
					    </NavLink>
						</motion.div>
				)
		    }
		    
		    if (node.id != "revelsend" && node.top && !node.parent) {
			    
			    if (node.crest) {
					return (
						<motion.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1, transition: {duration: 1, delay: .5} }}
						>
					    	<button style={{top: node.top, left: node.left}} className={node.labelposition} hidden={node.hidden} onClick={() => this.toggleModal(node.id, node.iframe)}>
					    	
							{node.subNodes &&
								<div className="count">
								{json.nodes.filter((subnode)=> {
									if (subnode.parent == node.id) {
										return node
									} else {
										return false
									}
								}).length}
								</div>
							}
							
							
								
					    		<img src={`./assets/crests/${node.id}-icon.svg`} class="crest" />
					    		<div className="label"><img src={`./assets/mapnames/${node.id}-hover.svg`} className="labelHover"/>
					    		<img src={`./assets/mapnames/${node.id}.svg`} className="label"/></div>
					    	</button>
						</motion.div>
					)
			    } else {
					return (
						<motion.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1, transition: {duration: 1, delay: .5} }}
						>
					    	<button style={{top: node.top, left: node.left}} className={node.labelposition} hidden={node.hidden} onClick={() => this.toggleModal(node.id, node.iframe)}><Targetmajor /><div className="label"><img src={`./assets/mapnames/${node.id}-hover.svg`} className="labelHover"/><img src={`./assets/mapnames/${node.id}.svg`} className="label"/></div></button>
						</motion.div>
					)
			    }
		    }
		})
		
		return (
			<>

			
			<h1 className="label icewind">
				<span/>
					<span>Icewind Dale</span>
				<span/>
			</h1>
			
			<div id="map">
				
				<div className="zoomContainer">
		
					<Snowfall
						color="white"
						style={{zIndex: 50}}
						snowflakeCount={100}
					/>
					
	                <TransformWrapper
		              defaultPositionX={1}
		              defaultPositionY={1}
	                  options={{
	                    limitToBounds: true,
	                    limitToWrapper: true,
	                    maxScale: this.state.maxZoom,
	                    minScale: this.state.minZoom
	                  }}
	                  wheel={{
		                  step: 100
	                  }}
	                  zoomIn={{
		                  animationTime: 600,
		                  step: 20
	                  }}
	                  zoomOut={{
		                  animationTime: 300
	                  }}
	                  doubleClick={{
		                  animationTime: 600
	                  }}
	                >
	                  {({
	                    zoomIn,
	                    zoomOut,
	                    setTransform,
	                    setScale,
	                    resetTransform,
	                    setDefaultState,
	                    positionX,
	                    positionY,
	                    scale,
	                    previousScale,
	                    options: { limitToBounds, transformEnabled, disabled },
	                    ...rest
	                  }) => (		                  
	                    <React.Fragment>				        	                  
	                      <div className="tools">
	                        <div className="spacer" />
	                        <button
	                          id="zoomIn"
	                          onClick={zoomIn}
	                          data-testid="zoom-in-button"
	                        >
	                          <img src={plus} />
	                        </button><br/>
	                        <button
	                          onClick={zoomOut}
	                          data-testid="zoom-out-button"
	                        >
	                          <img src={minus} />
	                        </button><br/>
	                        <button
	                          onClick={resetTransform}
	                          data-testid="reset-button"
	                        >
	                          <img src={expand} />
	                        </button>
	                        
	                        <div id="zoomInit" onClick={() => setTransform(this.state.defaultX, this.state.defaultY, this.state.initZoom, 800)}/>
	                      </div>
	                      <TransformComponent style={{overflow: "visible !important"}}>
	                        <div className="contentLayer" curScale={scale}>
                        		{nodesRendered}
                        		
						    	<img src={map} width="100%" className="mapImage" />
	                        </div>
	                      </TransformComponent>
	                    </React.Fragment>
	                  )}
	                </TransformWrapper>  
				</div>
			</div>
			
			<Modal isToggled={this.state.modalOpen} toggleModal={this.toggleModal.bind(this)}>
				{modal}
			</Modal>
			
			<SubnodeModal isToggled={this.state.subnodeModalOpen} toggleModal={this.toggleSubnodeModal.bind(this)}>
				{subnodeModal}
			</SubnodeModal>
			
			<InsetModal isToggled={this.state.insetModalOpen} toggleModal={this.toggleInsetModal.bind(this)} bg="rgba(0,0,0,.7)">
				{insetModal}
			</InsetModal>
			
			<IframeModal isToggled={this.state.iframeModalOpen} toggleModal={this.toggleIframeModal.bind(this)} bg="rgba(0,0,0,.7)">
				<div style={{ position: "relative", padding: "1em", boxSizing: "border-box", height: "100%" }}>
					<div class="iframeModalBody">
			  			<motion.button
			  				initial={{ x: -20, opacity: 0 }}
			  				animate={{ x: 0, opacity: 1, transition: {delay: .3, duration: .5} }}
			  				exit={{ x: -20, opacity: 0, transition: {duration: .5} }}
			  				className="closeButton"
			  				whileHover={{ backgroundColor: "#ff0000", transition: {delay: 0, duration: .3} }}
			  				onClick={() => this.toggleIframeModal()}
			  			>
							<img src={close} width="15"/>
			  			</motion.button>
			  			
			  			{this.state.iframeModal == "iframe" &&
							<Iframe source={this.state.iframeHref} />
			  			}
			  			
			  			{this.state.iframeModal == "image" &&
							<img src={this.state.iframeHref} style={{maxWidth: "70%", maxHeight: "80%", display: "block", margin: "0 auto", top: 100, position: "relative"}} />
			  			}
					</div>
				</div>
			</IframeModal>
			</>
		);
	}
}

export default MapComponent;