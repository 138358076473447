import React, { Component } from 'react';
import { motion } from "framer-motion";

import { NavLink, useLocation, withRouter } from "react-router-dom";

class HeaderLinks extends React.Component {
	
	constructor(props) {
        super(props);
        
        this.state = {
	        onAir: true
        };
    }
	
	componentDidMount(){
		
	}
    
    render() {
	    return (
			<>
			  	<div className="links">
				  	<NavLink to="/map" activeClassName="active">MAP</NavLink>
				  	<NavLink to="/watch" activeClassName="active">WATCH</NavLink>
				  	<a href="https://yawningportal.dnd.wizards.com/" target="_blank">PLAY</a>
				  	<NavLink to="/revelsend" activeClassName="active">REALITY RP</NavLink>
				  	<NavLink to="/marketplace" activeClassName="active">MARKETPLACE</NavLink>
				  	<NavLink to="/schedule" activeClassName="active">SCHEDULE</NavLink>
				  	<a href="https://discord.com/channels/720382370141700156/720386742632579134" target="_blank"><img src={this.props.discord} />CHAT</a>
				</div>
			</>
		)
    }
}

export default HeaderLinks;