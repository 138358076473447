import React from 'react';
import { motion } from "framer-motion";

import logo from "../assets/dnd-event-logo-black.svg";
import discord from "../assets/discord-brands.svg"; 

import HeaderLinks from "../components/HeaderLinks";
import Sidebar from "../components/Sidebar";

export const Header = () => (
		<>	
		<Sidebar />
		
		<header>
			
			<motion.div
				  initial={{ opacity: 0 }}
				  animate={{ opacity: 1 }}
				  exit={{ opacity: 0 }}
				>
				
				<div id="logo">
			  		<img src={logo} id="logo" width="100%" alt="D&D Logo" />
			  	</div>
			  	
			  	<HeaderLinks discord={discord} />
			  	
			</motion.div>
		</header>
		</>
)

export default Header;