import * as React from "react";
import { motion } from "framer-motion";

import Layout from "../Layout"

export const Play = () => (
  <>
	<Layout>
	  	<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
		>
		
			<div class="content">
				<h1>
					Play D&D
				</h1>
			</div>
							
		</motion.div>		
	</Layout>
  </>
);