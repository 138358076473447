import * as React from "react";
import { motion } from "framer-motion";

import Layout from "../Layout"
import rednose from "../assets/red-nose-day-logo.svg";
import mapbg from "../assets/map-background.jpg";
import book from "../assets/book-cover.png";
import dice from "../assets/dice-box-set.png";

export const Marketplace = () => (
  <>
	<Layout>
	  	<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
		>
			<div class="content" style={{marginLeft: "2rem", marginRight: "2rem"}}>
		
				<div class="marketplace" style={{backgroundImage: `url(${mapbg})` }}>
					<h1 className="label">
						<span/>
							<span>Marketplace</span>
						<span/>
					</h1>
					
					<div class="colInset">
					
						<h2>Make a donation to Red Nose Day</h2>
						
						<p>
							Dungeons & Dragons has partnered with Red Nose Day for D&D Live 2020 to help children affected by the COVID-19 outbreak. Red Nose Day is a charitable organization with a mission to end child poverty by funding programs that keep children safe, healthy, and educated. Through the power of entertainment, Red Nose Day brings people together to have fun while raising life-changing cash for the children that need it most. All proceeds from event donations go towards this mission.  For more information on Red Nose Day, visit their <a href="https://rednoseday.org/home?utm_source=NoTVHeader">website</a>.
						</p>
						<br/>
						
						<motion.a
						  whileHover={{
						    scale: 1.03,
						    transition: { duration: .5 },
						  }}
						  whileTap={{ backgroundColor: "#ea3223", transition: { duration: .1 } }}
						  className="button secondary"
						  href="https://donate.tiltify.com/@wizards-of-the-coast/dnd-live-2020"
						  target="_blank"
						>
							<i className="fal fa-donate"></i> <div>Make an event donation</div>
						</motion.a>
						
						<motion.a
						  whileHover={{
						    scale: 1.03,
						    transition: { duration: .5 },
						  }}
						  whileTap={{ backgroundColor: "#ea3223", transition: { duration: .1 } }}
						  className="button secondary"
						  href="https://www.customink.com/fundraising/dungeons-and-dragons-red-nose-day"
						  target="_blank"
						>
							<i className="fal fa-tshirt"></i> <div>Buy a Red Nose Day <span>t-shirt</span></div>
						</motion.a>
						
						<motion.a
						  whileHover={{
						    scale: 1.03,
						    transition: { duration: .5 },
						  }}
						  whileTap={{ backgroundColor: "#ea3223", transition: { duration: .1 } }}
						  className="button secondary"
						  href="https://www.dmsguild.com/product/314450/Return-to-the-Glory-5e"
						  target="_blank"
						>
								<i className="fal fa-dice-d20"></i> <div>Purchase a special adventure <span>on DM's Guild</span></div>
						</motion.a>
						
						<center>
							<a href="https://donate.rednoseday.org/" target="_blank"><img src={rednose}/></a>
						</center>
						
						<h2>Experience the new storyline, available September 15!</h2>
						
						<div className="flex2">
							
							<div className="whiteBlock">
								<div className="imageFrame">
									<img src={book} width="100%" />
								</div>
								
								<motion.a
								  whileHover={{
								    scale: 1.03,
								    backgroundColor: "#ff0000",
								    transition: { duration: .5 },
								  }}
								  whileTap={{ backgroundColor: "#ea3223", transition: { duration: .1 } }}
								  className="button"
								  href="https://www.amazon.com/Dungeons-Dragons-September-2020-Announced/dp/078696698X/">
								  	Pre-Order Book
								  </motion.a>
							</div>
							
							<div className="whiteBlock">
								<div className="imageFrame">
									<img src={dice} width="100%" />
								</div>
								
								<motion.a
								  whileHover={{
								    scale: 1.03,
								    backgroundColor: "#ff0000",
								    transition: { duration: .5 },
								  }}
								  whileTap={{ backgroundColor: "#ea3223", transition: { duration: .1 } }}
								  className="button"
								  href="https://www.amazon.com/September-2020-Accessory-Announced-Live/dp/0786967145/">
								  	Pre-Order Dice
								  </motion.a>
							</div>
							
						</div>
						<br/>
						<h2>Visit our officially licensed partners for more great D&D products!</h2>
						<br/>
						
					</div>
					
					<div class="logos">
						<div>
							<a href="https://baldursgate3.game/" target="_blank" target="_blank"><img src={`./assets/marketplace/baldurs-gate-logo.png`} /></a>
						</div>
						<div>
							<a href="http://wizkids.com/" target="_blank"><img src={`./assets/marketplace/wizkids-logo.png`} /></a>
						</div>
						<div>
							<a href="https://www.penguinrandomhouse.com/books/645417/heroes-feast-by-kyle-newman-jon-peterson-and-michael-witwer/" target="_blank"><img src={`./assets/marketplace/ten-speed-press-logo.png`} /></a>
						</div>
						<div>
							<a href="https://www.idwpublishing.com/" target="_blank"><img src={`./assets/marketplace/idw-logo.png`} /></a>
						</div>
						<div>
							<a href="https://www.dndbeyond.com/" target="_blank"><img src={`./assets/marketplace/dnd-beyond-logo.png`} /></a>
						</div>
						<div>
							<a href="https://www.dmsguild.com/" target="_blank"><img src={`./assets/marketplace/dungeon-masters-guild-logo.png`} /></a>
						</div>
						<div>
							<a href="https://www.beadleandgrimms.com/platinum/frost-maiden" target="_blank"><img src={`./assets/marketplace/beadle-and-grimms-logo.png`} /></a>
						</div>
						<div>
							<a href="https://shop.ultrapro.com/pages/dndlive2020" target="_blank"><img src={`./assets/marketplace/ultra-pro-logo.png`} /></a>
						</div>
						<div>
							<a href="https://marketplace.roll20.net/browse/bundle/5999/icewind-dale-rime-of-the-frost-maiden" target="_blank"><img src={`./assets/marketplace/roll20-logo.png`} /></a>
						</div>
						<div>
							<a href="https://www.fantasygrounds.com/store/?pub=29 " target="_blank"><img src={`./assets/marketplace/fantasy-grounds-logo.png`} /></a>
						</div>
						<div>
							<a href="https://syrinscape.com/dnd/?utm_source=dndlive&utm_medium=marketplace&utm_campaign=storelink" target="_blank"><img src={`./assets/marketplace/syrinscape-logo.png`} /></a>
						</div>
						<div>
							<a href="http://idlechampions.com/" target="_blank"><img src={`./assets/marketplace/idle-champions-logo.png`} /></a>
						</div>
						<div>
							<a href="https://www.playneverwinter.com/en?pwe_seg=20373&utm_medium=partner&utm_source=wotc&utm_term=pmm&utm_campaign=dndlive" target="_blank"><img src={`./assets/marketplace/cryptic-perfect-world-logo.png`} /></a>
						</div>
						<div>
							<a href="https://store.steampowered.com/sale/dungeonsdragons/" target="_blank"><img src={`./assets/marketplace/steam-logo.png`} /></a>
						</div>
						<div>
							<a href="https://store.steampowered.com/app/663380/Tales_from_Candlekeep_Tomb_of_Annihilation/" target="_blank"><img src={`./assets/marketplace/bkom-logo.png`} /></a>
						</div>
						<div>
							<a href="http://www.gog.com/promo/dd_live_sale" target="_blank"><img src={`./assets/marketplace/gogcom-logo.jpg`} /></a>
						</div>
						<div>
							<a href="https://www.gamestop.com/toys-collectibles/games-puzzles/trading-card-games/products/dungeons-and-dragons-dice-and-tray/11102567.html" target="_blank"><img src={`./assets/marketplace/gamestop-logo.png`} /></a>
						</div>
						<div>
							<a href="https://www.spirithalloween.com/dnd" target="_blank"><img src={`./assets/marketplace/spirit-logo.png`} /></a>
						</div>
						<div>
							<a href="https://heroesvillains.com/collections/dungeons-dragons" target="_blank"><img src={`./assets/marketplace/heroes-and-villains-logo.png`} /></a>
						</div>
						<div>
							<a href="https://www.80stees.com/collections/dungeons-and-dragons" target="_blank"><img src={`./assets/marketplace/80stees-logo.png`}/></a>
						</div>
						<div>
							<a href="https://bit.ly/FFBF_DND2020" target="_blank"><img src={`./assets/marketplace/for-fans-by-fans-logo.png`} /></a>
						</div>
						<div>
							<a href="https://us.zavvi.com/" target="_blank"><img src={`./assets/marketplace/zavvi-logo.png`} /></a>
						</div>
						<div>
							<a href="https://www.tervis.com/dungeons-and-dragons" target="_blank"><img src={`./assets/marketplace/tervis-logo.png`} /></a>
						</div>
						<div>
							<a href="https://bit.ly/3f3j777" target="_blank"><img src={`./assets/marketplace/bangarang-logo.png`} /></a>
						</div>
					</div>
				</div>

			</div>	
		</motion.div>		
	</Layout>
  </>
);