import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import axios from 'axios';

const Iframe = ({ source }) => {
	const [iframeData, setIframeData] = useState(0);

    if (!source) {
        return <div>Loading...</div>;
    }

    const src = source;
    
    function injectCSS(data) {
		var doc = new DOMParser().parseFromString(data, 'text/html');
		
		const style = doc.createElement("style");
		style.innerHTML = "@import url('https://fonts.googleapis.com/css2?family=Quattrocento+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap'); #page{font-family: 'Quattrocento Sans', sans-serif !important; background-color: transparent !important; color: #fff !important; width: 100% !important; margin: 0 !important; max-width: 100% !important; min-height: 100vh !important;} #page a{white-space: nowrap; color: #fff !important; background-color: rgba(255,255,255,.3) !important; font-weight: 700 !important; text-decoration: none !important; padding: .4em 1em !important; margin-top: .2em !important; border: none !important;} #page .fork a{padding: 1em 1em !important;} #backdrop{background-color: transparent !important;} #page a:hover, #page a:active{background-color: rgba(255, 2, 0, .7) !important;} img {width: 100%; max-width: 600px; margin: 3em auto; display: block;}";
		doc.head.appendChild(style);
		
		var urlPrefix = "{embed image: '/assets/twine/";
		var urlNeedle = "{embed image: '";
		
		var htmlBody = doc.body.outerHTML
		var res = htmlBody.replace(new RegExp(urlNeedle, 'g'), urlPrefix);
		
		setIframeData("<html>" + doc.head.outerHTML + res + "</html>");
    }
    
	axios.get(src)
		.then(res => {
			injectCSS(res.data);
		})
		
    return (
        <iframe srcdoc={iframeData}></iframe>
    );
};

export default Iframe;